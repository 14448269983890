import { camelcase } from './keymap';

export default {
  supportMail: 'info@el-medico.de',
  tenantName: 'El Medico',
  guruSessionsEnabled: false,
  cloudinary: camelcase({"cloud_name":"medgurus","preset_name":"learning-platform"}),
  bugsnagApiKey: camelcase("88c9dae4bf14128dfb76d7ccca410878"),
  mascot: 'AaLex das AaLpaka',
  mascotImage: 'aalex.svg',
  mascotAlt: 'AaLex the AaLpaka chewing on unsolvable puzzles'
};
